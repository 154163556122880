<template>
  <!-- <div class="ads-item" >
    <div v-show="isAdFilled" class="ads-borded" :class="classs">
      <div class="ads-borded-title">
        Advertisement
      </div>
      <ins ref="adsense" v-bind="ads" />
    </div>
    <div v-show="showDebug" class="ad-msg">
      {{ ads }}
    </div>
  </div> -->
</template>

<script>

export default {
  props: {
    ads: {
      type: [String, Object],
      required: true
    },
    showDebug: {
      type: Boolean,
      required: true
    },
    classs: {
      type: String,
      default: null,
      required: false
    },
  },
  data () {
    return {
      isAdFilled: true, // 默认显示
    }
  },
  mounted () {
    // this.observeAdStatus()
  },
  methods: {
    observeAdStatus () {
      const ads = this.$refs.adsense;

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.attributeName === 'data-ad-status') {
            console.log('Attribute changed:', mutation.target.getAttribute('data-ad-status'));
            this.isAdFilled = mutation.target.getAttribute('data-ad-status') !== 'unfilled';
          }
        });
      });

      observer.observe(ads, {
        attributes: true,
        attributeFilter: ['data-ad-status'],
      });

      // 初始化检查
      this.isAdFilled = ads.getAttribute('data-ad-status') !== 'unfilled';
    },
  },
}
</script>